/// <reference path="./types/bundler.d.ts" />
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';
import join from 'url-join';
import runtime from './runtime';

const instance = i18n.use(Fetch).use(detector).use(initReactI18next);

instance.init({
    supportedLngs: ['en'],
    interpolation: {
        escapeValue: false,
    },
    backend: {
        // path where resources get loaded from
        loadPath: (lngs: string[], ns: []) => {
            const url = new URL(runtime.translationsSink);
            const href = join(url.href, 'locales/{{lng}}/{{ns}}.json');

            if (__IS_DEV__) {
                return `${href}?t=${new Date().getTime()}`;
            }

            return `${href}?v=${runtime.appVersion}`;
        },

        // init option for fetch
        requestOptions: {
            mode: 'cors',
            credentials: 'omit',
            cache: 'default',
        },
    },
    ns: ['common', 'sidebar'],
    defaultNS: 'common',
    fallbackLng: ['en'],
});

export default instance;

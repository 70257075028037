import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import LazyApp from './LazyApp';
import { handleError } from './components/blocks/Error';
import i18n from './i18n';

// sentry is only initialized now as this package will import react-router-dom
// which may be heavy on browsers as well
// so we accept the risk some errors may not be caught if it's due to JS incompatibility on early imports
import './initializeSentry';

const beforeCapture: ErrorBoundaryProps['beforeCapture'] = scope => {
    scope.setTag('boundary', 'Bootstrap');
};

const Bootstrap = () => (
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <ErrorBoundary beforeCapture={beforeCapture} fallback={handleError}>
                <LazyApp />
            </ErrorBoundary>
        </I18nextProvider>
    </BrowserRouter>
);

export default Bootstrap;
